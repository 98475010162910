import * as React from "react"

let Visa = () => (
  <svg
    width="72"
    height="48"
    viewBox="0 0 72 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5006 32.8599H17.8272L14.3228 18.896C14.1564 18.2537 13.8032 17.6858 13.2837 17.4182C11.9872 16.7456 10.5585 16.2103 9 15.9403V15.4027H16.5284C17.5674 15.4027 18.3467 16.2103 18.4766 17.1482L20.2949 27.2208L24.966 15.4027H29.5095L22.5006 32.8599ZM32.107 32.8599H27.6934L31.3277 15.4027H35.7413L32.107 32.8599ZM41.4513 20.2388C41.5812 19.2986 42.3604 18.761 43.2696 18.761C44.6983 18.626 46.2545 18.896 47.5533 19.5663L48.3326 15.8076C47.0338 15.27 45.6051 15.0001 44.3086 15.0001C40.0249 15.0001 36.9078 17.4181 36.9078 20.7741C36.9078 23.3272 39.1158 24.6677 40.6743 25.4753C42.3604 26.2806 43.0098 26.8182 42.88 27.6234C42.88 28.8313 41.5812 29.3689 40.2847 29.3689C38.7261 29.3689 37.1676 28.9663 35.7412 28.2937L34.9619 32.0546C36.5205 32.7249 38.2066 32.9949 39.7652 32.9949C44.5684 33.1275 47.5533 30.7118 47.5533 27.0858C47.5533 22.5196 41.4513 22.252 41.4513 20.2388ZM63.0002 32.8599L59.4957 15.4027H55.7315C54.9522 15.4027 54.173 15.9403 53.9132 16.7456L47.4238 32.8599H51.9673L52.8742 30.3092H58.4567L58.9762 32.8599H63.0002ZM56.3807 20.1039L57.6773 26.6833H54.0429L56.3807 20.1039Z"
      fill="#172B85"
    />
  </svg>
)
export default Visa
