import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Phone from "../../svg/phone"
import Mastercard from "../../svg/home/mastercard"
import Gpay from "../../svg/home/gpay"
import ApplePay from "../../svg/home/apple-pay"
import Visa from "../../svg/home/visa"
import Rocket from "../../svg/rocket"

const Hero = () => (
  <div className={"hero"}>
    <div data-g={"grid"}>
      <div data-g="4 12@sm 6@md">
        <div className="hero__subtitle">
          <Rocket />
          10% rabatu za przejazd z jednego na drugi brzeg miasta
        </div>
        <h1 className="hero__title">
          Znamy Szczecin <br />
          jak własną kieszeń
        </h1>
        <p className="hero__description">
          Jesteśmy największą korporacją taxi na Prawobrzeżu. Działamy na
          terenie całego Szczecina, a także poza jego granicami.
        </p>
        <div className="hero__buttons">
          <a
            className={"button button--primary"}
            href={"tel:914-615-615"}
            data-g={"hide@md"}
          >
            <Phone />
            Zamów taxi
          </a>
          <button className={"button button--primary button--copy"}>
            <Phone />
            914 615 615
          </button>

          <Link className={"button button--secondary"} to={"/oferta"}>
            Poznaj ofertę
          </Link>
        </div>
        <hr className="separator hero__delimiter"></hr>
        <div className="hero__payment-methods">
          <Mastercard />
          <Visa />
          <Gpay />
          <ApplePay />
        </div>
      </div>
      <div data-g="4 12@sm 6@md">
        <StaticImage
          loading={"eager"}
          src="../../images/home-hero.png"
          alt="samochód taxi"
        />
      </div>
    </div>
  </div>
)

export default Hero
