import * as React from "react"

let GPay = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="48"
    viewBox="0 0 72 48"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.9056 30.2087V23.3773H37.2734C38.6535 23.3773 39.8183 22.8932 40.7678 21.9384L40.9957 21.6963C42.7303 19.7195 42.6163 16.64 40.7678 14.8111C39.8436 13.8429 38.5775 13.3185 37.2734 13.3453H31.8672V30.2087H33.9056ZM33.9062 21.3062V15.4161H37.3253C38.0597 15.4161 38.7562 15.712 39.2754 16.2499C40.377 17.3795 40.4024 19.2622 39.3387 20.4321C38.8195 21.0103 38.085 21.3331 37.3253 21.3062H33.9062ZM50.5039 19.5715C49.6303 18.7243 48.4401 18.294 46.9335 18.294C44.9964 18.294 43.5404 19.047 42.5781 20.5397L44.376 21.7366C45.0343 20.7145 45.9333 20.2035 47.0728 20.2035C47.7944 20.2035 48.4908 20.4859 49.0352 20.9969C49.567 21.4811 49.8708 22.1803 49.8708 22.92V23.4175C49.0859 22.9603 48.0983 22.7182 46.8828 22.7182C45.4648 22.7182 44.3253 23.0679 43.4771 23.7806C42.6288 24.4933 42.1983 25.4347 42.1983 26.6315C42.173 27.7208 42.6161 28.7562 43.4011 29.4555C44.1987 30.2086 45.2116 30.5851 46.4017 30.5851C47.8071 30.5851 48.9213 29.9262 49.7695 28.6083H49.8582V30.2086H51.808V23.0948C51.808 21.6021 51.3775 20.4187 50.5039 19.5715ZM44.9709 28.0839C44.5531 27.7611 44.2998 27.2367 44.2998 26.6719C44.2998 26.0399 44.5784 25.5154 45.1229 25.0985C45.6801 24.6816 46.3765 24.4665 47.1996 24.4665C48.3393 24.453 49.2257 24.722 49.8589 25.2599C49.8589 26.1743 49.517 26.9677 48.8458 27.6401C48.238 28.2856 47.4149 28.6487 46.5538 28.6487C45.984 28.6621 45.4268 28.4604 44.9709 28.0839ZM56.1892 35.2783L63.0008 18.6705H60.7852L57.6326 26.9542H57.5946L54.3661 18.6705H52.1504L56.6197 29.4689L54.0875 35.2783H56.1892Z"
      fill="#3C4043"
    />
    <path
      d="M26.8679 21.898C26.8679 21.2391 26.8172 20.5802 26.7159 19.9347H18.1191V23.6597H23.0443C22.8417 24.8565 22.1833 25.9323 21.2211 26.6047V29.0253H24.1584C25.8803 27.3443 26.8679 24.8565 26.8679 21.898Z"
      fill="#4285F4"
    />
    <path
      d="M18.1195 31.3653C20.5757 31.3653 22.6521 30.5046 24.1588 29.0254L21.2214 26.6048C20.3985 27.1965 19.3476 27.5327 18.1195 27.5327C15.7393 27.5327 13.7262 25.8249 13.0045 23.5388H9.97852V26.04C11.5232 29.3078 14.6757 31.3653 18.1195 31.3653Z"
      fill="#34A853"
    />
    <path
      d="M13.0047 23.5387C12.6248 22.3419 12.6248 21.0374 13.0047 19.8272V17.3394H9.97821C8.67393 20.0692 8.67393 23.2966 9.97821 26.0265L13.0047 23.5387Z"
      fill="#FBBC04"
    />
    <path
      d="M18.1195 15.8332C19.4236 15.8063 20.677 16.3308 21.6139 17.2856L24.2221 14.5154C22.5635 12.8747 20.3858 11.9738 18.1195 12.0006C14.6757 12.0006 11.5232 14.0716 9.97852 17.3393L13.0045 19.8406C13.7262 17.5411 15.7393 15.8332 18.1195 15.8332Z"
      fill="#EA4335"
    />
  </svg>
)
export default GPay
