import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Home/hero"
import Estimate from "../components/Common/estimate"
import Mobile from "../components/Common/mobile"
import Features from "../components/Common/features"
import Offer from "../components/Home/offer"
import Clients from "../components/Common/clients"
import Join from "../components/Common/join"

const IndexPage = () => (
  <Layout>
    <Seo
      title={"Strona główna"}
      description={
        "Od ponad 25 lat świadczymy usługi dla mieszkańców Szczecina. Zamów już teraz przejazd Taxi Szczecin lub oblicz koszt kursu na naszej stronie."
      }
    />
    <div data-g="container">
      <Hero />
    </div>

    <Estimate />

    <div data-g="container">
      <hr className="separator"></hr>

      <Mobile />
      <Features />

      <hr className="separator"></hr>

      <Offer />

      <Clients />
    </div>
    <Join />
  </Layout>
)

export default IndexPage
