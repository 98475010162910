import * as React from "react"
import { Link } from "gatsby"

const Offer = () => {
  return (
    <div className={"offer-types"}>
      <div data-g="grid">
        <div data-g="4 12@sm 6@md" className={"offer-types__wrapper offer-types__wrapper--white"}>
          <div className="offer-types__background offer-types__background--left">
          </div>
          <h3>Oferta dla biznesu</h3>
          <p>
            Usprawnij działanie swojej firmy poprzez regularną obsługę swoich
            klientów i pracowników.
          </p>
          <Link className={"button button--secondary"} to={"/dla-firm"}>
            Dowiedz się więcej
          </Link>
        </div>
        <div data-g="4 12@sm  6@md" className={"offer-types__wrapper"}>
          <div className="offer-types__background offer-types__background--right">
          </div>
          <h3>Oferta indywidualna</h3>
          <p>
            Profesjonalnie zadbamy o bezpieczny oraz komfortowy transport dla
            Ciebie i Twoich bliskich.
          </p>
          <Link className={"button button--secondary"} to={"/oferta"}>
            Dowiedz się więcej
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Offer
