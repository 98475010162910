import * as React from "react"

let Rocket = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path d="M2.83652 17.7131C1.1683 19.3123 3.35756 21.0297 0 23.3565C0 23.3565 1.97589 24.0105 3.76411 21.7822C4.37898 21.0159 5.08937 20.6939 5.08937 20.6939C5.33273 20.8377 5.14673 21.6176 4.28298 22.2627C4.28298 22.2627 5.63057 22.2967 6.47248 21.4634C7.31438 20.63 7.88678 19.4297 7.88678 19.4297C8.46325 20.4337 7.01991 21.7053 7.01991 21.7053C7.01991 21.7053 8.38237 21.3538 9.99851 19.7534C11.2556 18.5087 12.1496 15.4152 10.3484 13.6318C8.54725 11.8484 5.32889 12.1737 3.75187 13.7352C3.06164 14.4186 2.35485 15.486 2.26413 16.3631C2.26413 16.3631 3.59179 14.9438 4.06123 15.5419C4.06123 15.5419 1.81558 16.8859 1.45462 18.1622C1.45486 18.1622 2.57253 17.5018 2.83652 17.7131Z" fill="url(#paint0_linear_436_6539)" />
    <path d="M1.59544 18.9338C-0.15222 19.6289 0.973129 21.0798 0.222656 21.8272C0.222656 21.8272 1.22849 21.2825 1.21217 20.2168C1.19897 19.3487 1.59544 18.9338 1.59544 18.9338Z" fill="url(#paint1_linear_436_6539)" />
    <path d="M2.00391 23.9795C2.4515 23.9267 3.20845 22.6371 4.23612 22.8512C4.23612 22.8512 3.31477 24.1796 2.00391 23.9795Z" fill="url(#paint2_linear_436_6539)" />
    <path opacity="0.5" d="M7.52984 21.3895C8.078 21.1866 9.01254 20.7299 9.99845 19.7537C11.1024 18.6606 12.0228 15.5566 10.2137 13.7654C8.44543 12.0145 5.34491 12.3679 3.85789 13.8405C3.32078 14.3723 2.75271 15.1681 2.52687 15.9038C2.85087 15.6182 3.32438 15.2658 3.72157 15.2287C3.92533 15.2097 4.09285 15.276 4.20589 15.42L4.31941 15.5645L4.12261 15.6826C4.10317 15.6942 2.35839 16.7488 1.76872 17.8179C2.17311 17.614 2.73399 17.3895 2.96198 17.572L3.09158 17.6758L2.9399 17.8215C2.31711 18.4184 2.28735 19.0365 2.25279 19.7518C2.20335 20.7733 2.14768 21.9242 0.392578 23.258C0.98897 23.3369 2.35551 23.2989 3.63998 21.698C4.28317 20.8964 5.0202 20.5564 5.05116 20.5424L5.1354 20.5043L5.20092 20.5431C5.32283 20.6146 5.37707 20.7676 5.35451 20.9732C5.32355 21.2541 5.14308 21.6607 4.74012 22.0653C5.17908 22.0095 5.87627 21.8443 6.36658 21.3589C7.16985 20.5635 7.72904 19.4015 7.73456 19.3899L7.89128 19.0612L8.04104 19.3219C8.42983 19.9994 7.99712 20.8256 7.52984 21.3895Z" fill="url(#paint3_linear_436_6539)" />
    <path d="M0.222656 21.8272C0.71537 21.4304 1.21888 20.655 1.21216 20.2168C1.20592 19.8159 1.31704 19.2487 1.59544 18.9338C0.373374 19.448 0.542332 20.2607 0.69809 20.663C0.766009 20.8391 0.67409 21.3631 0.222656 21.8272Z" fill="url(#paint4_radial_436_6539)" />
    <path d="M1.17188 23.0201C3.49072 22.2608 4.1116 19.6531 5.60534 19.2006C7.09908 18.7482 6.723 20.1626 6.14797 20.8004C6.14797 20.8004 8.1193 18.8409 8.13154 17.3077C8.14378 15.7745 10.2195 18.1679 9.57561 19.4136C9.57561 19.4136 12.7476 16.2745 10.2135 13.7653C7.67939 11.2562 4.30407 13.7596 4.30407 13.7596C4.30407 13.7596 6.22549 13.6822 6.05941 15.063C6.05941 15.063 4.57671 15.6188 3.41081 16.5451C3.41081 16.5451 3.98368 16.6052 4.37559 17.1865C4.37559 17.1865 2.63202 18.3773 2.70089 19.4045C2.78969 20.7234 1.91443 22.777 1.17188 23.0201Z" fill="url(#paint5_linear_436_6539)" />
    <path d="M2.79492 21.033C3.87971 20.7638 4.83489 18.2518 6.35599 18.3908C7.1967 18.4676 7.00206 19.1861 7.00206 19.1861C7.00206 19.1861 7.58549 18.3635 7.71917 17.2511C7.85285 16.1388 9.94322 16.5192 9.85058 18.6059C9.85058 18.6059 12.1495 15.6459 9.36146 13.7661C6.28543 11.692 4.03762 14.2955 4.03762 14.2955C4.03762 14.2955 6.13927 13.6023 6.46591 15.2546C6.46591 15.2546 4.72713 15.7336 4.08754 16.3671C4.08754 16.3671 4.83153 16.4705 5.00913 17.2573C5.00913 17.2573 3.29579 18.4975 3.34715 18.9903C3.47099 20.1799 2.79492 21.033 2.79492 21.033Z" fill="url(#paint6_linear_436_6539)" />
    <path opacity="0.5" d="M2.83652 17.7131C1.1683 19.3123 3.35756 21.0297 0 23.3565C0 23.3565 1.97589 24.0105 3.76411 21.7822C4.37898 21.0159 5.08937 20.6939 5.08937 20.6939C5.33273 20.8377 5.14673 21.6176 4.28298 22.2627C4.28298 22.2627 5.63057 22.2967 6.47248 21.4634C7.31438 20.63 7.88678 19.4297 7.88678 19.4297C8.46325 20.4337 7.01991 21.7053 7.01991 21.7053C7.01991 21.7053 8.38237 21.3538 9.99851 19.7534C11.2556 18.5087 12.1496 15.4152 10.3484 13.6318C8.54725 11.8484 5.32889 12.1737 3.75187 13.7352C3.06164 14.4186 2.35485 15.486 2.26413 16.3631C2.26413 16.3631 3.59179 14.9438 4.06123 15.5419C4.06123 15.5419 1.81558 16.8859 1.45462 18.1622C1.45486 18.1622 2.57253 17.5018 2.83652 17.7131Z" fill="url(#paint7_radial_436_6539)" />
    <path opacity="0.5" d="M2.83652 17.7131C1.1683 19.3123 3.35756 21.0297 0 23.3565C0 23.3565 1.97589 24.0105 3.76411 21.7822C4.37898 21.0159 5.08937 20.6939 5.08937 20.6939C5.33273 20.8377 5.14673 21.6176 4.28298 22.2627C4.28298 22.2627 5.63057 22.2967 6.47248 21.4634C7.31438 20.63 7.88678 19.4297 7.88678 19.4297C8.46325 20.4337 7.01991 21.7053 7.01991 21.7053C7.01991 21.7053 8.38237 21.3538 9.99851 19.7534C11.2556 18.5087 12.1496 15.4152 10.3484 13.6318C8.54725 11.8484 5.32889 12.1737 3.75187 13.7352C3.06164 14.4186 2.35485 15.486 2.26413 16.3631C2.26413 16.3631 3.59179 14.9438 4.06123 15.5419C4.06123 15.5419 1.81558 16.8859 1.45462 18.1622C1.45486 18.1622 2.57253 17.5018 2.83652 17.7131Z" fill="url(#paint8_radial_436_6539)" />
    <path d="M9.1511 20.2775C10.7754 18.5642 11.6963 15.4998 9.99949 13.8197C8.30271 12.1397 5.18035 13.5512 3.79436 14.8185C3.79436 14.8185 4.89667 14.3572 5.7693 14.9107C6.64193 15.4641 4.15244 16.4134 3.63477 17.9202C3.63477 17.9202 5.85498 16.6 6.55049 17.1594C7.246 17.7187 6.54065 19.4767 5.8737 20.1371C5.8737 20.1371 7.46416 19.3318 8.32167 18.2917C9.62557 16.7102 9.1511 20.2775 9.1511 20.2775Z" fill="url(#paint9_radial_436_6539)" />
    <path opacity="0.5" d="M2.83652 17.7131C1.1683 19.3123 3.35756 21.0297 0 23.3565C0 23.3565 1.97589 24.0105 3.76411 21.7822C4.37898 21.0159 5.08937 20.6939 5.08937 20.6939C5.33273 20.8377 5.14673 21.6176 4.28298 22.2627C4.28298 22.2627 5.63057 22.2967 6.47248 21.4634C7.31438 20.63 7.88678 19.4297 7.88678 19.4297C8.46325 20.4337 7.01991 21.7053 7.01991 21.7053C7.01991 21.7053 8.38237 21.3538 9.99851 19.7534C11.2556 18.5087 12.1496 15.4152 10.3484 13.6318C8.54725 11.8484 5.32889 12.1737 3.75187 13.7352C3.06164 14.4186 2.35485 15.486 2.26413 16.3631C2.26413 16.3631 3.59179 14.9438 4.06123 15.5419C4.06123 15.5419 1.81558 16.8859 1.45462 18.1622C1.45486 18.1622 2.57253 17.5018 2.83652 17.7131Z" fill="url(#paint10_radial_436_6539)" />
    <path opacity="0.5" d="M1.59544 18.9338C-0.15222 19.6289 0.973129 21.0798 0.222656 21.8272C0.222656 21.8272 1.22849 21.2825 1.21217 20.2168C1.19897 19.3487 1.59544 18.9338 1.59544 18.9338Z" fill="url(#paint11_radial_436_6539)" />
    <path d="M2.00391 23.9793C2.4515 23.9265 3.10837 23.0229 4.23612 22.8511C4.23612 22.8511 3.31477 24.1794 2.00391 23.9793Z" fill="url(#paint12_radial_436_6539)" />
    <path opacity="0.5" d="M2.00391 23.9795C2.4515 23.9267 3.20845 22.6371 4.23612 22.8512C4.23612 22.8512 3.31477 24.1796 2.00391 23.9795Z" fill="url(#paint13_radial_436_6539)" />
    <path opacity="0.5" d="M2.83652 17.7131C1.1683 19.3123 3.35756 21.0297 0 23.3565C0 23.3565 1.97589 24.0105 3.76411 21.7822C4.37898 21.0159 5.08937 20.6939 5.08937 20.6939C5.33273 20.8377 5.14673 21.6176 4.28298 22.2627C4.28298 22.2627 5.63057 22.2967 6.47248 21.4634C7.31438 20.63 7.88678 19.4297 7.88678 19.4297C8.46325 20.4337 7.01991 21.7053 7.01991 21.7053C7.01991 21.7053 8.38237 21.3538 9.99851 19.7534C11.2556 18.5087 12.1496 15.4152 10.3484 13.6318C8.54725 11.8484 5.32889 12.1737 3.75187 13.7352C3.06164 14.4186 2.35485 15.486 2.26413 16.3631C2.26413 16.3631 3.59179 14.9438 4.06123 15.5419C4.06123 15.5419 1.81558 16.8859 1.45462 18.1622C1.45486 18.1622 2.57253 17.5018 2.83652 17.7131Z" fill="url(#paint14_radial_436_6539)" />
    <path opacity="0.25" d="M2.83652 17.7131C1.1683 19.3123 3.35756 21.0297 0 23.3565C0 23.3565 1.97589 24.0105 3.76411 21.7822C4.37898 21.0159 5.08937 20.6939 5.08937 20.6939C5.33273 20.8377 5.14673 21.6176 4.28298 22.2627C4.28298 22.2627 5.63057 22.2967 6.47248 21.4634C7.31438 20.63 7.88678 19.4297 7.88678 19.4297C8.46325 20.4337 7.01991 21.7053 7.01991 21.7053C7.01991 21.7053 8.38237 21.3538 9.99851 19.7534C11.2556 18.5087 12.1496 15.4152 10.3484 13.6318C8.54725 11.8484 5.32889 12.1737 3.75187 13.7352C3.06164 14.4186 2.35485 15.486 2.26413 16.3631C2.26413 16.3631 3.59179 14.9438 4.06123 15.5419C4.06123 15.5419 1.81558 16.8859 1.45462 18.1622C1.45486 18.1622 2.57253 17.5018 2.83652 17.7131Z" fill="url(#paint15_radial_436_6539)" />
    <path opacity="0.75" d="M2.83652 17.7131C1.1683 19.3123 3.35756 21.0297 0 23.3565C0 23.3565 1.97589 24.0105 3.76411 21.7822C4.37898 21.0159 5.08937 20.6939 5.08937 20.6939C5.33273 20.8377 5.14673 21.6176 4.28298 22.2627C4.28298 22.2627 5.63057 22.2967 6.47248 21.4634C7.31438 20.63 7.88678 19.4297 7.88678 19.4297C8.46325 20.4337 7.01991 21.7053 7.01991 21.7053C7.01991 21.7053 8.38237 21.3538 9.99851 19.7534C11.2556 18.5087 12.1496 15.4152 10.3484 13.6318C8.54725 11.8484 5.32889 12.1737 3.75187 13.7352C3.06164 14.4186 2.35485 15.486 2.26413 16.3631C2.26413 16.3631 3.59179 14.9438 4.06123 15.5419C4.06123 15.5419 1.81558 16.8859 1.45462 18.1622C1.45486 18.1622 2.57253 17.5018 2.83652 17.7131Z" fill="url(#paint16_radial_436_6539)" />
    <path d="M16.3031 16.1386C16.1425 14.5643 16.5109 12.8923 17.3516 11.3796L17.618 10.9003L13.5074 13.5648L13.1627 16.0117L11.5586 17.5385C11.9042 18.8675 11.9179 20.164 11.9179 20.164C14.6421 18.5453 16.3031 16.1386 16.3031 16.1386Z" fill="url(#paint17_linear_436_6539)" />
    <path d="M11.9179 20.1641C11.9179 20.1641 11.908 18.8484 11.5586 17.5386L12.2361 16.8939C12.4427 17.347 12.8471 18.3598 12.9479 19.4833C12.6223 19.7148 12.2822 19.9524 11.9179 20.1641Z" fill="url(#paint18_linear_436_6539)" />
    <path opacity="0.75" d="M16.3031 16.1386C16.1425 14.5643 16.5109 12.8923 17.3516 11.3796L17.618 10.9003L13.5074 13.5648L13.1627 16.0117L11.5586 17.5385C11.9042 18.8675 11.9179 20.164 11.9179 20.164C14.6421 18.5453 16.3031 16.1386 16.3031 16.1386Z" fill="url(#paint19_radial_436_6539)" />
    <path d="M16.7037 12.8384C16.8725 12.3418 17.0894 11.853 17.3522 11.3799L17.5015 11.1111C17.4657 11.09 17.4293 11.0686 17.3925 11.0472L13.5082 13.5649L13.2754 15.2183C14.1612 14.8623 15.4145 14.1233 16.7037 12.8384Z" fill="url(#paint20_linear_436_6539)" />
    <path d="M12.242 6.70292C12.2026 6.60692 12.175 6.53634 12.1407 6.43891C10.609 7.16178 8.87553 7.36139 7.20468 7.05247C7.20468 7.05247 4.73631 8.65338 3.22266 11.4912C3.22266 11.4912 4.63504 11.6245 6.11365 12.1244L7.62947 10.5055L10.0846 10.3617C10.634 9.1555 11.3014 7.84806 12.242 6.70292Z" fill="url(#paint21_linear_436_6539)" />
    <path d="M3.22266 11.4912C3.22266 11.4912 4.63503 11.6245 6.11365 12.1245L6.76164 11.4325C6.24205 11.1666 5.11407 10.6471 3.86441 10.4335C3.63929 10.764 3.42257 11.1165 3.22266 11.4912Z" fill="url(#paint22_linear_436_6539)" />
    <path opacity="0.75" d="M12.242 6.70292C12.2026 6.60692 12.175 6.53634 12.1407 6.43891C10.609 7.16178 8.87553 7.36139 7.20468 7.05247C7.20468 7.05247 4.73631 8.65338 3.22266 11.4912C3.22266 11.4912 4.63504 11.6245 6.11365 12.1244L7.62947 10.5055L10.0846 10.3617C10.634 9.1555 11.3014 7.84806 12.242 6.70292Z" fill="url(#paint23_radial_436_6539)" />
    <path d="M12.1413 6.43891C11.5442 6.89397 9.6667 8.6838 9.01367 10.4247L10.0853 10.3619C10.6344 9.15573 11.4662 7.82002 12.4068 6.67464L12.1413 6.43891Z" fill="url(#paint24_linear_436_6539)" />
    <path d="M12.7878 6.08969C13.4257 7.11459 14.2633 8.12855 15.2008 9.03226C15.2454 9.07527 15.2903 9.11828 15.3354 9.16082C16.1267 9.90745 16.9844 10.5738 17.8498 11.102C17.8494 11.1025 17.8489 11.103 17.8484 11.1034C17.9274 11.0279 18.0068 10.953 18.0848 10.8756C20.3331 8.64944 21.6324 6.98271 22.7513 4.85687C21.9523 3.3263 20.641 2.02789 19.0949 1.23659C16.9479 2.34442 15.2646 3.63094 13.0163 5.85705C12.9385 5.93405 12.8632 6.01246 12.7871 6.09041C12.7873 6.08993 12.7876 6.08993 12.7878 6.08969Z" fill="url(#paint25_linear_436_6539)" />
    <path d="M22.7501 4.85655C24.2717 1.96603 24.0785 0.418583 23.8272 0.169784C23.5759 -0.0790139 22.0133 -0.270068 19.0938 1.23627C20.6398 2.02757 21.9512 3.32598 22.7501 4.85655Z" fill="url(#paint26_linear_436_6539)" />
    <path d="M10.2125 13.6504C11.0203 14.4503 11.7619 15.0332 11.9611 15.0346C13.7695 14.2504 15.9323 12.9356 17.8487 11.1018C16.9833 10.5736 16.1255 9.90728 15.3342 9.16064C15.2891 9.11811 15.2442 9.07533 15.1996 9.03209C14.2624 8.12838 13.4246 7.11441 12.7867 6.08952C10.9313 7.99103 9.60552 10.1418 8.81641 11.9366C8.84857 12.1567 9.42672 12.8727 10.2125 13.6504Z" fill="url(#paint27_linear_436_6539)" />
    <path opacity="0.75" d="M10.2125 13.6504C11.0203 14.4503 11.7619 15.0332 11.9611 15.0346C13.7695 14.2504 15.9323 12.9356 17.8487 11.1018C16.9833 10.5736 16.1255 9.90728 15.3342 9.16064C15.2891 9.11811 15.2442 9.07533 15.1996 9.03209C14.2624 8.12838 13.4246 7.11441 12.7867 6.08952C10.9313 7.99103 9.60552 10.1418 8.81641 11.9366C8.84857 12.1567 9.42672 12.8727 10.2125 13.6504Z" fill="url(#paint28_radial_436_6539)" />
    <path opacity="0.5" d="M15.3365 9.16064C15.2914 9.11811 15.2465 9.07533 15.2018 9.03209C14.2647 8.12838 13.4268 7.11441 12.7889 6.08952C12.7421 6.13752 12.6972 6.18623 12.6509 6.23447C13.9503 8.66566 17.9102 10.9749 14.8865 12.2009C12.9015 13.0057 12.0123 13.9574 11.5352 14.8065C11.7375 14.9514 11.8887 15.0339 11.9633 15.0344C13.7717 14.2502 15.9346 12.9354 17.8509 11.1016C16.9855 10.5736 16.128 9.90728 15.3365 9.16064Z" fill="url(#paint29_radial_436_6539)" />
    <path d="M10.2136 13.6503C9.42784 12.8723 8.84968 12.1566 8.81728 11.9365L7.70466 12.4206C7.57338 12.5506 8.30681 13.4871 9.34264 14.5127C10.3785 15.5383 11.3245 16.2643 11.4556 16.1345L11.9622 15.0345C11.763 15.0331 11.0212 14.4502 10.2136 13.6503Z" fill="url(#paint30_linear_436_6539)" />
    <path d="M10.2136 13.6503C9.42784 12.8723 8.84968 12.1566 8.81728 11.9365L7.70466 12.4206C7.57338 12.5506 8.30681 13.4871 9.34264 14.5127C10.3785 15.5383 11.3245 16.2643 11.4556 16.1345L11.9622 15.0345C11.763 15.0331 11.0212 14.4502 10.2136 13.6503Z" fill="url(#paint31_linear_436_6539)" />
    <path d="M22.444 5.41989C22.4548 5.39945 22.7402 4.87714 22.751 4.8567C21.952 3.32613 20.6407 2.02772 19.0946 1.23641C19.0738 1.24711 19.0538 1.25827 19.0332 1.26897C20.5466 2.12277 22.2628 4.04211 22.444 5.41989Z" fill="url(#paint32_linear_436_6539)" />
    <path opacity="0.75" d="M23.8273 0.169967C23.576 -0.0788307 22.0134 -0.269885 19.0938 1.23645C16.9468 2.34428 15.2635 3.6308 13.0152 5.85691C12.9374 5.9339 12.862 6.01232 12.786 6.09026C12.7862 6.09003 12.7864 6.08979 12.7867 6.08955C10.9313 7.9913 9.60552 10.1418 8.81641 11.9367C8.84881 12.1567 9.42696 12.8724 10.2127 13.6504C11.0205 14.4503 11.7621 15.0332 11.9613 15.0346C13.7697 14.2505 15.9326 12.9357 17.8489 11.1019C17.8485 11.1023 17.848 11.1028 17.8475 11.1033C17.9265 11.0277 18.0057 10.9529 18.0839 10.8754C20.3322 8.6493 21.6315 6.98256 22.7504 4.85673C24.2717 1.96597 24.0786 0.418765 23.8273 0.169967Z" fill="url(#paint33_radial_436_6539)" />
    <path d="M8.49115 17.2553C10.8842 15.3447 12.7597 13.2089 12.8082 13.1533L12.4343 12.7831C12.4343 12.7831 10.4826 14.8954 8.14844 16.9207L8.49115 17.2553Z" fill="url(#paint34_linear_436_6539)" />
    <path d="M12.4343 12.783C12.8689 11.9629 13.6052 10.933 14.5463 9.82474C14.5232 9.80359 14.5 9.78315 14.4772 9.76176C13.4572 10.7199 12.4487 11.5879 11.5389 12.3541L10.4853 13.8856L8.89723 15.4328C8.62219 16.1245 8.14844 16.9206 8.14844 16.9206C10.4826 14.8953 12.4343 12.783 12.4343 12.783Z" fill="url(#paint35_linear_436_6539)" />
    <path d="M8.97738 16.1845C8.97882 16.1821 9.53489 15.1857 9.48377 14.8616L8.89722 15.4331C8.62219 16.1248 8.14844 16.9209 8.14844 16.9209C8.43139 16.6754 8.70835 16.4285 8.97738 16.1845Z" fill="url(#paint36_linear_436_6539)" />
    <path d="M12.8104 13.1508C13.1224 12.2069 13.8313 11.1464 14.7323 9.99365C14.6699 9.93852 14.608 9.88173 14.5463 9.82494C13.6052 10.9332 12.8689 11.9631 12.4343 12.7832C12.4343 12.7832 10.4826 14.8955 8.14844 16.9208L8.49115 17.2554C10.9156 15.3197 12.8104 13.1508 12.8104 13.1508Z" fill="url(#paint37_linear_436_6539)" />
    <path d="M8.49115 17.2553C10.8842 15.3447 12.7597 13.2089 12.8082 13.1533L12.4343 12.7831C12.4343 12.7831 10.4826 14.8954 8.14844 16.9207L8.49115 17.2553Z" fill="url(#paint38_linear_436_6539)" />
    <path opacity="0.75" d="M12.4343 12.783C12.8689 11.9629 13.6052 10.933 14.5463 9.82474C14.5232 9.80359 14.5 9.78315 14.4772 9.76176C13.4572 10.7199 12.4487 11.5879 11.5389 12.3541L10.4853 13.8856L8.89723 15.4328C8.62219 16.1245 8.14844 16.9206 8.14844 16.9206C10.4826 14.8953 12.4343 12.783 12.4343 12.783Z" fill="url(#paint39_radial_436_6539)" />
    <path opacity="0.75" d="M23.8273 0.169967C23.576 -0.0788307 22.0134 -0.269885 19.0938 1.23645C16.9468 2.34428 15.2635 3.6308 13.0152 5.85691C12.9374 5.9339 12.862 6.01232 12.786 6.09026C12.7862 6.09003 12.7864 6.08979 12.7867 6.08955C10.9313 7.9913 9.60552 10.1418 8.81641 11.9367C8.84881 12.1567 9.42696 12.8724 10.2127 13.6504C11.0205 14.4503 11.7621 15.0332 11.9613 15.0346C13.7697 14.2505 15.9326 12.9357 17.8489 11.1019C17.8485 11.1023 17.848 11.1028 17.8475 11.1033C17.9265 11.0277 18.0057 10.9529 18.0839 10.8754C20.3322 8.6493 21.6315 6.98256 22.7504 4.85673C24.2717 1.96597 24.0786 0.418765 23.8273 0.169967Z" fill="url(#paint40_radial_436_6539)" />
    <g opacity="0.16">
      <path opacity="0.16" d="M22.1169 5.99725C22.1114 5.99725 22.1058 5.99464 22.1025 5.98988C21.9136 5.72778 21.7204 5.47637 21.5118 5.22116C21.5056 5.21355 21.5068 5.20238 21.5145 5.19644C21.5221 5.19026 21.5332 5.19145 21.5394 5.19906C21.7485 5.45498 21.9422 5.70711 22.1313 5.96968C22.1371 5.97753 22.1351 5.98869 22.1272 5.9944C22.1241 5.99606 22.1205 5.99725 22.1169 5.99725Z" fill="black" />
      <path opacity="0.16" d="M22.3176 4.07314C22.3228 4.06483 22.3204 4.0539 22.312 4.04867C22.3039 4.04368 22.2926 4.04582 22.2873 4.05413C22.2429 4.12352 22.1971 4.1941 22.15 4.26562C20.9532 6.08896 19.715 7.57818 17.7391 9.57071C17.1065 9.11755 16.4828 8.59833 15.8996 8.02113C15.5125 7.63783 15.1532 7.23528 14.8179 6.82585C16.8526 4.83356 18.3701 3.51448 20.1009 2.23508C20.1962 2.1645 20.2898 2.09606 20.3813 2.03C20.3892 2.0243 20.3909 2.01313 20.3851 2.00529C20.3793 1.99721 20.3681 1.99555 20.3601 2.00149C20.2687 2.06779 20.1753 2.13622 20.0796 2.2068C18.3483 3.48667 16.8298 4.80623 14.7951 6.799C14.3698 6.27716 13.9875 5.74344 13.6719 5.21187C13.6669 5.20331 13.6559 5.20046 13.6475 5.20569C13.6388 5.21068 13.6362 5.22137 13.6412 5.22993C13.9582 5.76388 14.3425 6.30021 14.7697 6.82419C14.6396 6.95179 14.5143 7.07298 14.3799 7.20629C14.3185 7.26689 14.2582 7.32772 14.198 7.38856L14.1488 7.43798C14.1483 7.43846 14.1481 7.4387 14.1478 7.43917C14.0737 7.51355 14.0002 7.58817 13.9273 7.66278C13.9206 7.66967 13.9206 7.68084 13.9278 7.68773C13.9314 7.69106 13.9359 7.69272 13.9403 7.69272C13.9448 7.69272 13.9496 7.69082 13.9532 7.68726C14.0266 7.61217 14.1006 7.53731 14.175 7.46222C14.1754 7.46198 14.1757 7.46151 14.1759 7.46127L14.2234 7.41351C14.2837 7.35267 14.3442 7.29208 14.4051 7.23148C14.539 7.09889 14.6634 6.97841 14.7927 6.85151C15.1285 7.26095 15.4877 7.66373 15.8746 8.04703C16.4607 8.62708 17.088 9.14916 17.7243 9.60422C17.725 9.60707 17.7221 9.60992 17.7243 9.6123C17.7276 9.61562 17.7324 9.61729 17.7367 9.61729C17.7382 9.61729 17.7391 9.6161 17.7403 9.61586C18.0663 9.84826 18.3939 10.0685 18.721 10.2589C18.7239 10.2606 18.727 10.2613 18.7301 10.2613C18.7361 10.2613 18.7421 10.2582 18.7455 10.2525C18.7503 10.2439 18.7476 10.2332 18.7392 10.2282C18.4155 10.04 18.0915 9.82212 17.7687 9.59234C19.7443 7.59981 20.9832 6.10963 22.1805 4.28511C22.2271 4.21335 22.2729 4.14277 22.3176 4.07314Z" fill="black" />
    </g>
    <path opacity="0.75" d="M11.457 14.5357C13.0998 13.5878 15.1268 12.1382 17.0326 10.294C17.0322 10.2944 17.0317 10.2949 17.0312 10.2954" fill="url(#paint41_radial_436_6539)" />
    <path d="M18.804 6.28355C17.8884 7.11882 16.5351 7.12548 15.781 6.29829C15.0269 5.4711 15.1577 4.1235 16.0733 3.28823C16.9889 2.45296 18.3422 2.44631 19.0963 3.2735C19.8504 4.10069 19.7196 5.44829 18.804 6.28355Z" fill="url(#paint42_linear_436_6539)" />
    <path d="M18.4095 5.81668C17.659 6.50153 16.5493 6.507 15.931 5.8288C15.3128 5.15084 15.4201 4.04587 16.1705 3.36125C16.921 2.67641 18.0308 2.67094 18.649 3.34914C19.2672 4.02709 19.1599 5.13183 18.4095 5.81668Z" fill="url(#paint43_linear_436_6539)" />
    <path d="M18.238 5.63289C17.5686 6.2436 16.5794 6.24835 16.0281 5.64382C15.4768 5.03929 15.5726 4.05407 16.2417 3.4436C16.9108 2.83313 17.9003 2.82814 18.4516 3.43267C19.0028 4.0372 18.9073 5.02218 18.238 5.63289Z" fill="url(#paint44_radial_436_6539)" />
    <path opacity="0.5" d="M18.238 5.63289C17.5686 6.2436 16.5794 6.24835 16.0281 5.64382C15.4768 5.03929 15.5726 4.05407 16.2417 3.4436C16.9108 2.83313 17.9003 2.82814 18.4516 3.43267C19.0028 4.0372 18.9073 5.02218 18.238 5.63289Z" fill="url(#paint45_radial_436_6539)" />
    <path opacity="0.5" d="M18.3175 3.87938C18.5702 4.15646 18.7058 4.50149 18.7327 4.86198C18.8904 4.36153 18.8037 3.81926 18.4509 3.4324C17.8996 2.82787 16.9104 2.83262 16.241 3.44333C16.1738 3.50464 16.114 3.57046 16.0586 3.63842C16.8134 3.18645 17.7576 3.26511 18.3175 3.87938Z" fill="black" />
    <path d="M18.4517 3.4324C17.9004 2.82787 16.9111 2.83262 16.2418 3.44333C15.5724 4.05404 15.4769 5.03902 16.0282 5.64355C16.0855 5.70629 16.1415 5.75286 16.1069 5.72078C15.6135 5.23221 15.7647 4.23084 16.4009 3.60088C16.9063 3.10043 17.892 2.91199 18.4548 3.4362" fill="url(#paint46_linear_436_6539)" />
    <path opacity="0.5" d="M18.4512 3.43257L18.4544 3.43637C17.8916 2.91216 16.9059 3.1006 16.4005 3.60105C15.7921 4.20344 15.6286 5.14397 16.0465 5.6525C16.1125 5.68553 16.1377 5.7504 16.1062 5.72117C16.0846 5.69979 16.0657 5.67603 16.0465 5.6525C16.0402 5.64941 16.0342 5.64632 16.0275 5.64371C16.5788 6.24824 17.5681 6.24348 18.2374 5.63278C18.9068 5.02231 19.0025 4.03733 18.4512 3.43257Z" fill="url(#paint47_radial_436_6539)" />
    <path d="M22.7599 4.86401C22.7503 4.86401 22.7409 4.85878 22.7361 4.84975C21.9367 3.31799 20.646 2.03931 19.1024 1.24942C19.0892 1.24277 19.0841 1.22685 19.0909 1.21378C19.0976 1.20071 19.1139 1.19572 19.1269 1.20237C20.6803 1.99748 21.9792 3.28401 22.7836 4.82552C22.7903 4.83858 22.7853 4.85451 22.7721 4.86116C22.7683 4.86306 22.7642 4.86401 22.7599 4.86401Z" fill="#008EE6" />
    <path d="M17.8505 11.1282C17.8457 11.1282 17.8409 11.127 17.8366 11.1242C16.9884 10.6066 16.1172 9.93412 15.3176 9.17965C15.2724 9.13711 15.2273 9.0941 15.1827 9.05085C14.2299 8.13217 13.3942 7.11298 12.7657 6.10329C12.758 6.09093 12.7618 6.07454 12.7745 6.06693C12.7868 6.05933 12.8036 6.06313 12.8113 6.07549C13.4374 7.08161 14.2705 8.09724 15.2201 9.01283C15.2648 9.05584 15.3096 9.09861 15.3545 9.14115C16.1516 9.89301 17.0194 10.5631 17.8644 11.0788C17.8772 11.0864 17.881 11.1028 17.8733 11.1151C17.8683 11.1237 17.8596 11.1282 17.8505 11.1282Z" fill="#008EE6" />
    <defs>
      <linearGradient id="paint0_linear_436_6539" x1="9.94963" y1="12.705" x2="-1.25985" y2="27.6507" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF0000" />
        <stop offset="0.2317" stopColor="#FF1500" />
        <stop offset="0.7367" stopColor="#FF3D00" />
        <stop offset="0.9987" stopColor="#FF4C00" />
      </linearGradient>
      <linearGradient id="paint1_linear_436_6539" x1="7.76878" y1="11.3087" x2="-3.30562" y2="26.0744" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF0000" />
        <stop offset="0.2317" stopColor="#FF1500" />
        <stop offset="0.7367" stopColor="#FF3D00" />
        <stop offset="0.9987" stopColor="#FF4C00" />
      </linearGradient>
      <linearGradient id="paint2_linear_436_6539" x1="10.9714" y1="13.1534" x2="-0.38855" y2="28.2997" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF0000" />
        <stop offset="0.2317" stopColor="#FF1500" />
        <stop offset="0.7367" stopColor="#FF3D00" />
        <stop offset="0.9987" stopColor="#FF4C00" />
      </linearGradient>
      <linearGradient id="paint3_linear_436_6539" x1="7.70031" y1="15.0315" x2="0.506558" y2="30.1262" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF8000" />
        <stop offset="0.6388" stopColor="#FF5B00" />
        <stop offset="0.9987" stopColor="#FF4C00" />
      </linearGradient>
      <radialGradient id="paint4_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(-2.71473 22.879) rotate(-135.284) scale(5.86717 7.45001)">
        <stop offset="0.00134333" stopColor="#FFED1C" stopOpacity="0.6" />
        <stop offset="0.9477" stopColor="#FFED1C" stopOpacity="0.0264782" />
        <stop offset="0.9914" stopColor="#FFED1C" stopOpacity={0} />
      </radialGradient>
      <linearGradient id="paint5_linear_436_6539" x1="1.77152" y1="23.9896" x2="8.28097" y2="14.5628" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF8000" />
        <stop offset="0.022669" stopColor="#FE7F02" stopOpacity="0.9773" />
        <stop offset="0.6663" stopColor="#ED582A" stopOpacity="0.3337" />
        <stop offset={1} stopColor="#E74939" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="paint6_linear_436_6539" x1="9.35214" y1="14.3021" x2="3.43901" y2="19.6528" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBC47" />
        <stop offset="0.1869" stopColor="#FFB137" stopOpacity="0.8131" />
        <stop offset="0.7223" stopColor="#FF940F" stopOpacity="0.2777" />
        <stop offset={1} stopColor="#FF8900" stopOpacity={0} />
      </linearGradient>
      <radialGradient id="paint7_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(7.86566 15.8435) rotate(-135.284) scale(4.53857 4.67792)">
        <stop offset="0.00134333" stopColor="#FFBC47" />
        <stop offset="0.2941" stopColor="#FFBF4B" stopOpacity="0.7068" />
        <stop offset="0.5624" stopColor="#FEC656" stopOpacity="0.4382" />
        <stop offset="0.8201" stopColor="#FCD368" stopOpacity="0.1801" />
        <stop offset={1} stopColor="#FBE07A" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint8_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(7.69732 16.0102) rotate(-135.284) scale(4.39872 4.53377)">
        <stop offset="0.00134333" stopColor="white" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint9_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(9.80134 16.641) rotate(-135.284) scale(5.40352 7.98964)">
        <stop offset="0.00134333" stopColor="#FFED1C" stopOpacity="0.6" />
        <stop offset="0.9477" stopColor="#FFED1C" stopOpacity="0.0264782" />
        <stop offset="0.9914" stopColor="#FFED1C" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint10_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(7.70017 16.0086) rotate(-42.4443) scale(2.1691 1.38645)">
        <stop offset="0.00134333" stopColor="white" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint11_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(0.348567 21.9574) rotate(-135.284) scale(1.93846 2.46142)">
        <stop offset="0.00134333" stopColor="#FFED1C" />
        <stop offset="0.1997" stopColor="#FFEE2B" stopOpacity="0.8013" />
        <stop offset="0.5839" stopColor="#FFF254" stopOpacity="0.4167" />
        <stop offset={1} stopColor="#FFF686" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint12_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(3.46195 23.3729) rotate(162.759) scale(1.88229 0.37776)">
        <stop offset="0.00134333" stopColor="#FFED1C" stopOpacity="0.6" />
        <stop offset="0.9477" stopColor="#FFED1C" stopOpacity="0.0264782" />
        <stop offset="0.9914" stopColor="#FFED1C" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint13_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(2.12972 24.2542) rotate(-40.7381) scale(1.79052 0.568077)">
        <stop offset="0.00134333" stopColor="#FFED1C" />
        <stop offset="0.1997" stopColor="#FFEE2B" stopOpacity="0.8013" />
        <stop offset="0.5839" stopColor="#FFF254" stopOpacity="0.4167" />
        <stop offset={1} stopColor="#FFF686" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint14_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(0.43049 22.4827) rotate(-135.284) scale(6.04856 7.68034)">
        <stop offset="0.00134333" stopColor="#FF4C00" />
        <stop offset="0.135" stopColor="#FF5B0C" stopOpacity="0.8661" />
        <stop offset="0.3941" stopColor="#FF842C" stopOpacity="0.6067" />
        <stop offset="0.7491" stopColor="#FFC45F" stopOpacity="0.2512" />
        <stop offset={1} stopColor="#FFF686" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint15_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(2.89737 16.1689) rotate(-135.284) scale(3.21048 4.0766)">
        <stop offset="0.00134333" stopColor="#FFED1C" />
        <stop offset="0.1997" stopColor="#FFEE2B" stopOpacity="0.8013" />
        <stop offset="0.5839" stopColor="#FFF254" stopOpacity="0.4167" />
        <stop offset={1} stopColor="#FFF686" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint16_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(7.69118 16.3161) rotate(-135.284) scale(6.71351 8.52468)">
        <stop offset="0.00134333" stopColor="#FFED1C" />
        <stop offset="0.1997" stopColor="#FFEE2B" stopOpacity="0.8013" />
        <stop offset="0.5839" stopColor="#FFF254" stopOpacity="0.4167" />
        <stop offset={1} stopColor="#FFF686" stopOpacity={0} />
      </radialGradient>
      <linearGradient id="paint17_linear_436_6539" x1="9.37837" y1="20.8307" x2="16.6117" y2="12.9524" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BD2719" />
        <stop offset="0.2151" stopColor="#D41A10" />
        <stop offset="0.5049" stopColor="#EC0C07" />
        <stop offset="0.774" stopColor="#FA0302" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <linearGradient id="paint18_linear_436_6539" x1="13.6289" y1="17.173" x2="11.3576" y2="19.4261" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FC8D41" />
        <stop offset="0.0900324" stopColor="#FC7837" />
        <stop offset="0.294" stopColor="#FD4D24" />
        <stop offset="0.4922" stopColor="#FE2C14" />
        <stop offset="0.6802" stopColor="#FF1409" />
        <stop offset="0.854" stopColor="#FF0502" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <radialGradient id="paint19_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(8.32381 19.8448) rotate(44.716) scale(9.84272 9.84224)">
        <stop offset="0.00134333" stopColor="#FFED1C" />
        <stop offset="0.1997" stopColor="#FFEE2B" stopOpacity="0.8013" />
        <stop offset="0.5839" stopColor="#FFF254" stopOpacity="0.4167" />
        <stop offset={1} stopColor="#FFF686" stopOpacity={0} />
      </radialGradient>
      <linearGradient id="paint20_linear_436_6539" x1="13.8008" y1="14.6993" x2="25.0043" y2="1.58472" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BD2719" />
        <stop offset="0.2151" stopColor="#D41A10" />
        <stop offset="0.5049" stopColor="#EC0C07" />
        <stop offset="0.774" stopColor="#FA0302" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <linearGradient id="paint21_linear_436_6539" x1="2.34489" y1="11.7685" x2="9.22254" y2="8.35947" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BD2719" />
        <stop offset="0.2151" stopColor="#D41A10" />
        <stop offset="0.5049" stopColor="#EC0C07" />
        <stop offset="0.774" stopColor="#FA0302" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <linearGradient id="paint22_linear_436_6539" x1="6.28606" y1="9.78776" x2="4.14038" y2="12.3637" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FC8D41" />
        <stop offset="0.0900324" stopColor="#FC7837" />
        <stop offset="0.294" stopColor="#FD4D24" />
        <stop offset="0.4922" stopColor="#FE2C14" />
        <stop offset="0.6802" stopColor="#FF1409" />
        <stop offset="0.854" stopColor="#FF0502" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <radialGradient id="paint23_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(7.34113 15.9514) rotate(44.7161) scale(10.5868 10.5863)">
        <stop offset="0.00134333" stopColor="#FFED1C" />
        <stop offset="0.1997" stopColor="#FFEE2B" stopOpacity="0.8013" />
        <stop offset="0.5839" stopColor="#FFF254" stopOpacity="0.4167" />
        <stop offset={1} stopColor="#FFF686" stopOpacity={0} />
      </radialGradient>
      <linearGradient id="paint24_linear_436_6539" x1="11.893" y1="8.37077" x2="0.758101" y2="8.89666" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BD2719" />
        <stop offset="0.2151" stopColor="#D41A10" />
        <stop offset="0.5049" stopColor="#EC0C07" />
        <stop offset="0.774" stopColor="#FA0302" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <linearGradient id="paint25_linear_436_6539" x1="15.9216" y1="3.64382" x2="20.3584" y2="8.1248" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BBCACC" />
        <stop offset="0.3717" stopColor="white" />
        <stop offset={1} stopColor="#99AFB3" />
      </linearGradient>
      <linearGradient id="paint26_linear_436_6539" x1="23.5164" y1="3.34349" x2="21.1072" y2="0.185613" gradientUnits="userSpaceOnUse">
        <stop stopColor="#008EE6" />
        <stop offset="0.688" stopColor="#80DAFE" />
        <stop offset="0.7325" stopColor="#76D5FB" />
        <stop offset="0.8071" stopColor="#5CC8F3" />
        <stop offset="0.9026" stopColor="#32B2E6" />
        <stop offset={1} stopColor="#0099D6" />
      </linearGradient>
      <linearGradient id="paint27_linear_436_6539" x1="14.9328" y1="13.2513" x2="9.8904" y2="8.18596" gradientUnits="userSpaceOnUse">
        <stop stopColor="#008EE6" />
        <stop offset="0.688" stopColor="#80DAFE" />
        <stop offset="0.7325" stopColor="#76D5FB" />
        <stop offset="0.8071" stopColor="#5CC8F3" />
        <stop offset="0.9026" stopColor="#32B2E6" />
        <stop offset={1} stopColor="#0099D6" />
      </linearGradient>
      <radialGradient id="paint28_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(11.4745 14.9152) rotate(44.716) scale(4.82176 8.8248)">
        <stop stopOpacity="0.75" />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint29_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(17.124 13.785) rotate(44.7159) scale(4.82176 8.82481)">
        <stop stopOpacity="0.75" />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <linearGradient id="paint30_linear_436_6539" x1="8.34022" y1="11.8938" x2="11.4944" y2="16.0766" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="0.1962" stopColor="#4D4D4C" />
        <stop offset="0.7655" />
      </linearGradient>
      <linearGradient id="paint31_linear_436_6539" x1="8.34022" y1="11.8938" x2="11.4944" y2="16.0766" gradientUnits="userSpaceOnUse">
        <stop stopOpacity={0} />
        <stop offset="0.1975" stopColor="#CFDCDE" stopOpacity="0.5" />
        <stop offset="0.7655" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="paint32_linear_436_6539" x1="15.0293" y1="2.0306" x2="22.3469" y2="3.71808" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BBCACC" />
        <stop offset="0.3717" stopColor="white" />
        <stop offset={1} stopColor="#99AFB3" />
      </linearGradient>
      <radialGradient id="paint33_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(17.2036 11.1183) rotate(43.9504) scale(3.11143 10.0726)">
        <stop stopColor="#440063" stopOpacity="0.75" />
        <stop offset={1} stopColor="#420061" stopOpacity={0} />
      </radialGradient>
      <linearGradient id="paint34_linear_436_6539" x1="7.95338" y1="16.6211" x2="16.3213" y2="11.172" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BD2719" />
        <stop offset="0.2151" stopColor="#D41A10" />
        <stop offset="0.5049" stopColor="#EC0C07" />
        <stop offset="0.774" stopColor="#FA0302" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <linearGradient id="paint35_linear_436_6539" x1="6.5861" y1="16.9044" x2="10.8957" y2="13.5906" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BD2719" />
        <stop offset="0.2151" stopColor="#D41A10" />
        <stop offset="0.5049" stopColor="#EC0C07" />
        <stop offset="0.774" stopColor="#FA0302" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <linearGradient id="paint36_linear_436_6539" x1="10.7155" y1="14.1495" x2="8.25356" y2="16.472" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FC8D41" />
        <stop offset="0.0900324" stopColor="#FC7837" />
        <stop offset="0.294" stopColor="#FD4D24" />
        <stop offset="0.4922" stopColor="#FE2C14" />
        <stop offset="0.6802" stopColor="#FF1409" />
        <stop offset="0.854" stopColor="#FF0502" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <linearGradient id="paint37_linear_436_6539" x1="10.6338" y1="15.7421" x2="13.527" y2="7.3803" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BD2719" />
        <stop offset="0.2151" stopColor="#D41A10" />
        <stop offset="0.5049" stopColor="#EC0C07" />
        <stop offset="0.774" stopColor="#FA0302" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <linearGradient id="paint38_linear_436_6539" x1="7.95338" y1="16.6211" x2="16.3213" y2="11.172" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BD2719" />
        <stop offset="0.2151" stopColor="#D41A10" />
        <stop offset="0.5049" stopColor="#EC0C07" />
        <stop offset="0.774" stopColor="#FA0302" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <radialGradient id="paint39_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(7.88858 15.8976) rotate(-38.7724) scale(7.42552 2.4849)">
        <stop offset="0.00134333" stopColor="#FFED1C" />
        <stop offset="0.1997" stopColor="#FFEE2B" stopOpacity="0.8013" />
        <stop offset="0.5839" stopColor="#FFF254" stopOpacity="0.4167" />
        <stop offset={1} stopColor="#FFF686" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint40_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(15.0411 6.07111) rotate(43.9504) scale(3.11143 10.0726)">
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint41_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(14.3482 13.1539) rotate(43.5808) scale(0.755508 3.62604)">
        <stop stopColor="#440063" stopOpacity="0.75" />
        <stop offset={1} stopColor="#420061" stopOpacity={0} />
      </radialGradient>
      <linearGradient id="paint42_linear_436_6539" x1="16.259" y1="7.30099" x2="18.5715" y2="2.27162" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BD2719" />
        <stop offset="0.2151" stopColor="#D41A10" />
        <stop offset="0.5049" stopColor="#EC0C07" />
        <stop offset="0.774" stopColor="#FA0302" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <linearGradient id="paint43_linear_436_6539" x1="17.9337" y1="2.47341" x2="16.6768" y2="6.68789" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FC8D41" />
        <stop offset="0.0900324" stopColor="#FC7837" />
        <stop offset="0.294" stopColor="#FD4D24" />
        <stop offset="0.4922" stopColor="#FE2C14" />
        <stop offset="0.6802" stopColor="#FF1409" />
        <stop offset="0.854" stopColor="#FF0502" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
      <radialGradient id="paint44_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(16.6459 4.59504) rotate(44.7123) scale(4.11371 4.11322)">
        <stop stopColor="#512D00" />
        <stop offset={1} />
      </radialGradient>
      <radialGradient id="paint45_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(17.4413 3.7939) scale(3.6269 3.59115)">
        <stop offset="0.4873" stopColor="#F5CBC0" stopOpacity={0} />
        <stop offset={1} stopColor="#E67E62" />
      </radialGradient>
      <linearGradient id="paint46_linear_436_6539" x1="17.1053" y1="3.28883" x2="17.2567" y2="0.57389" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BD2719" />
        <stop offset="0.0422031" stopColor="#B42717" />
        <stop offset="0.257" stopColor="#89250F" />
        <stop offset="0.4654" stopColor="#682408" />
        <stop offset="0.6634" stopColor="#502304" />
        <stop offset="0.8464" stopColor="#412201" />
        <stop offset={1} stopColor="#3C2200" />
      </linearGradient>
      <radialGradient id="paint47_radial_436_6539" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(12.324 2.9146) scale(4.91821 4.8697)">
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </radialGradient>
    </defs>
  </svg>
)
export default Rocket
