import * as React from "react"
import { Link } from "gatsby"

const Clients = () => {
  return (
    <div className={"clients"}>
      <div className="text-separator">
        <div className="text-separator__text">Zaufali nam</div>
      </div>
      <div data-g="grid" className={"clients__client"}>
        <div data-g="2 6@sm 3@md vertical-center">
          <a
            href="https://autobruno.volvocars-partner.pl/"
            target={"_blank"}
            rel={"nofollow noopener noreferrer"}
          >
            <img
              src="/images/clients/volvo.png"
              alt="volvo"
              className={"volvo"}
            />
          </a>
        </div>
        <div data-g="2 6@sm 3@md" className={"clients__client"}>
          <a
            href="https://grupapolmotor.pl/"
            target={"_blank"}
            rel={"nofollow noopener noreferrer"}
          >
            <img
              src="/images/clients/polmotor.png"
              alt="polmotor"
              className={"polmotor"}
            />
          </a>
        </div>
        <div data-g="2 6@sm 3@md" className={"clients__client"}>
          <a
            href="https://galeria-gryf.pl/"
            target={"_blank"}
            rel={"nofollow noopener noreferrer"}
          >
            <img
              src="/images/clients/galeria-gryf.png"
              alt="galeria gryf"
              className={"gryf"}
            />
          </a>
        </div>
        <div data-g="2 6@sm 3@md" className={"clients__client"}>
          <a
            href="https://www.helios.pl/23,Szczecin/StronaGlowna/"
            target={"_blank"}
            rel={"nofollow noopener noreferrer"}
          >
            <img
              src="/images/clients/helios.png"
              alt="helios"
              className={"helios"}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Clients
